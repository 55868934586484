<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-center">
            <v-col cols="12" sm="10" md="8" lg="6">

                <v-card class="pa-2 pa-md-4">
                    <v-card-text class="text-body-1 text--primary text-hyphenate">
                        <header>
                            <div class="text-h3 mb-2">
                                FAQ
                            </div>
                            <pre>
                                Häufig gestellte Fragen zum Service-Portal für Apotheken
                                Stand: Februar 2023
                            </pre>
                        </header>
                        <nav class="mb-6">
                            <header class="text-h4">
                                Inhaltsverzeichnis
                            </header>
                            <v-simple-table class="mt-2 toc" dense>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="item in toc">
                                            <td>{{ item.id }}</td>
                                            <td>
                                                <a @click="$vuetify.goTo(item.selector)">
                                                    {{ item.text }}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </nav>
                        <article ref="content">
                            <section>
                                <header class="text-h5">
                                    Registrierung und Anmeldung
                                </header>
                                <section>
                                    <header class="text-h6">
                                        Wozu brauche ich den Teilnahme-Code?
                                    </header>
                                    <p>
                                        Sie benötigen Ihren persönlichen Teilnahme-Code, um das Service-Portal zu nutzen. Der Teilnahme-Code wird individuell
                                        für Ihr IK generiert und ermöglicht eine eindeutige Zuordnung zu Ihren Daten.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Woher bekomme ich meinen Teilnahme-Code?
                                    </header>
                                    <p>
                                        Im Rahmen der monatlich versendeten Beanstandungsbriefe lassen wir Ihnen eine Einladung zum Service-Portal postalisch zukommen,
                                        in der Ihr persönlicher Teilnahme-Code enthalten ist. Falls Sie gerade keine Beanstandungen haben, können Sie Ihren
                                        persönlichen Teilnahme-Code auch gerne postalisch anfordern, indem Sie auf den Button "Registrieren" klicken und dann
                                        auf "Ich möchte einen Teilnahme-Code anfordern".
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Wie kann ich den QR-Code vom Einladungsbrief einscannen?
                                    </header>
                                    <p>
                                        Sie können den QR-Code, in dem Ihr Teilnahme-Code enthalten ist, mit einer Webcam oder mit der Kamera Ihres Mobilgerätes
                                        einscannen. Danach werden Sie automatisch auf die Registrierungsseite geleitet, auf der Sie Ihre Email-Adresse und Ihr
                                        Passwort vergeben können.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Ich kann den QR-Code nicht einscannen.
                                    </header>
                                    <p>
                                        Sie können alternativ Ihren Teilnahme-Code auch manuell auf der Registrierungsseite eintragen. Klicken Sie dazu auf
                                        den Button "Registrieren" und dann auf "Ich habe einen Teilnahme-Code".
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Ich habe keine Email mit Verifizierungs-Link erhalten.
                                    </header>
                                    <p>
                                        Als letzten Schritt der Registrierung senden wir Ihnen eine Email mit einem Link zur Verifizierung Ihrer Email-Adresse.
                                        Diesen Link müssen Sie anklicken, um die Registrierung abzuschließen. Falls Sie die Email nicht innerhalb weniger Minuten
                                        erhalten, prüfen Sie bitte Ihren Spam-Ordner. Falls die Email tatsächlich nicht angekommen ist, kontaktieren Sie uns bitte
                                        über das Kontakt-Formular in der Fußzeile. Wir senden Ihnen dann die Email erneut zu.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Ich habe vergessen, den Link in der Email anzuklicken.
                                    </header>
                                    <p>
                                        Der Verifizierungs-Link in der Email ist 24 Stunden gültig. Falls Sie den Link nicht innerhalb der nächsten 24 Stunden anklicken, ist er nicht
                                        mehr gültig und die Registrierung kann nicht abgeschlossen werden. Bitte beginnen Sie Ihre Registrierung erneut oder
                                        kontaktieren Sie uns über das Kontakt-Formular in der Fußzeile.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Ich habe mein Passwort vergessen.
                                    </header>
                                    <p>
                                        Bitte nutzen Sie die Funktion "Passwort vergessen?" auf der Anmeldeseite, damit wir Ihnen einen Link zum
                                        Zurücksetzen des Passwortes per Email zusenden können.
                                    </p>
                                </section>
                            </section>
                            <section>
                                <header class="text-h5">
                                    Zwei-Faktor-Authentifizierung
                                </header>
                                <section>
                                    <header class="text-h6">
                                        Was ist Zwei-Faktor-Authentifizierung (2FA)?
                                    </header>
                                    <p>
                                        Um die Datensicherheit zu gewährleisten, reicht ein Benutzername und Passwort für die Anmeldung im Service-
                                        Portal nicht aus. Sie benötigen einen zweiten Faktor, der in diesem Fall ein Mobilgerät mit einer installierten
                                        Authenticator-App darstellt. In dieser App wird ein sechsstelliger Zahlencode angezeigt, der sich alle 30 Sekunden
                                        ändert. Diesen Zahlencode benötigen Sie zusätzlich zu Ihrem Passwort für jede Anmeldung.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Benötige ich dafür ein Handy?
                                    </header>
                                    <p>
                                        Ja, sie benötigen als zweiten Faktor ein Mobilgerät, auf dem eine Authenticator-App installiert werden muss. Es
                                        kann sich dabei aber auch um ein Tablet o.ä. handeln. Aus Sicherheitsgründen können Sie dafür nicht denselben Computer nutzen,
                                        auf dem Sie das Service-Portal aufrufen.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Was mache ich, wenn kein Handy zur Verfügung steht?
                                    </header>
                                    <p>
                                        Statt eines Handys oder Tablets können sie auch ein separates Gerät wie z.B. den <a href="https://shop.reiner-sct.com/authenticator/reiner-sct-authenticator">Authenticator von ReinerSCT</a> verwenden
                                        und dies in Ihrer Apotheke für Ihre Mitarbeiter bereitlegen.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Welche Authenticator-App soll ich benutzen?
                                    </header>
                                    <p>
                                        Sie können eine beliebige Authenticator-App herunterladen und nutzen. Wir empfehlen
                                        Google Authenticator, Microsoft Authenticator, FreeOTP oder Authy.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Wie bekomme ich den zweiten Faktor für das Service-Portal in die Authenticator-App?
                                    </header>
                                    <p>
                                        Nachdem Sie im ersten Schritt der Registrierung Ihre Email-Adresse angegeben und Ihr Passwort gewählt haben,
                                        wird im zweiten Schritt ein QR-Code angezeigt, den Sie mit Ihrer Authenticator-App oder Ihrem Authenticator-Gerät
                                        einscannen können. Erstellen Sie dazu in der App ein neues Konto, erlauben Sie ggf. die Nutzung der Kamera und scannen
                                        Sie den QR-Code ab. Das Konto wird nun automatisch erstellt und alle 30 Sekunden eine neue sechstellige Zahl generiert, die Sie in das
                                        entsprechende Feld im Service-Portal eintragen müssen. Wenn Sie den QR-Code nicht einscannen können, so können Sie
                                        auch mithilfe des angezeigten Codes ein neues Konto in Ihrer Authenticator-App erstellen. Wählen Sie dazu in der
                                        App die Option "Code manuell eingeben" (o.ä.)
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Ich habe mein Handy verloren bzw. die Authenticator-App oder das Konto innerhalb der App gelöscht.
                                    </header>
                                    <p>
                                        Melden Sie sich zunächst mit Ihrem Benutzername und Passwort im Service-Portal an.
                                        Auf der folgenden Seite zur Verifizierung mit dem Sicherheitscode klicken Sie auf "AUTHENTICATOR VERLOREN?".
                                        Nach dem Anklicken des Buttons "BESTÄTIGEN" erhalten Sie eine E-Mail mit der Beschreibung des Vorgehens
                                        zur Wiederherstellung der Zwei-Faktor-Authentifizierung auf Ihrem Gerät.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Ich möchte ein weiteres Mobilgerät für die Zwei-Faktor-Authentifizierung nutzen.
                                    </header>
                                    <p>
                                        Melden Sie sich im Service-Portal an und gehen Sie in den Menüpunkt Einstellungen.
                                        Wählen Sie "Authenticator ergänzen" aus. Nach Eingabe Ihres Passwortes und dem Sicherheitscode
                                        und Anklicken des Buttons "SENDEN" wird Ihnen ein QR-Code angezeigt. Diesen können Sie mit Ihrem
                                        zweiten Gerät (Handy, Tablet, ReinerSCT etc.) scannen. Beide Geräte können nun für die
                                        Zwei-Faktor-Authentifizierung genutzt werden.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Mein sechsstelliger Zahlencode wird nicht akzeptiert
                                    </header>
                                    <p>
                                        Die Generierung des Zahlencodes basiert auf der Uhrzeit des Systems. Bitte stellen Sie sicher, dass die Uhrzeiten der beiden
                                        Geräte (das Gerät, auf dem der Authenticator läuft und das, auf dem Sie das Service-Portal aufrufen) identisch sind.
                                    </p>
                                </section>
                            </section>
                            <section>
                                <header class="text-h5">
                                    Fachliche Fragen
                                </header>
                                <section>
                                    <header class="text-h6">
                                        Welche Krankenkassen nehmen am Service-Portal teil?
                                    </header>
                                    <p>
                                        Aktuell (Stand Feb. 2023) nehmen die BARMER, die DAK-Gesundheit, die Hanseatische Krankenkasse und die Mobil Krankenkasse teil. Sobald weitere Kassen
                                        am Service-Portal teilnehmen, werden registrierte Apotheken automatisch per Email benachrichtigt.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h6">
                                        Werde ich bei Beanstandungen automatisch benachrichtigt?
                                    </header>
                                    <p>
                                        Ja, sie werden per Email benachrichtigt, sobald neue Beanstandungen im Portal zur Verfügung stehen oder Einsprüche 
                                        durch uns entschieden werden. Sie müssen sich daher nicht in regelmäßigen Abständen ins Portal einloggen.
                                    </p>
                                </section>
                            </section>
                            <section>
                                <header class="text-h5">
                                    Kündigung
                                </header>
                                <section>
                                    <header class="text-h6">
                                        Wie kann ich kündigen?
                                    </header>
                                    <p>
                                        Wenn Sie kündigen möchten, nutzen Sie bitte die Funktion "Konto löschen" direkt im Portal.
                                        Die Kündigung wird laut Nutzungsbedingungen zum Ende des übernächsten Monats wirksam. Ab diesem Zeitpunkt 
                                        erhalten Sie dann ihre Beanstandungen wieder auf dem Postweg.
                                    </p>
                                </section>
                            </section>
                        </article>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: 'FAQ',
        data: () => ({
            toc: [],
        }),
        mounted: function () {

            // Ids für alle header in sections hinzufügen und Inhaltsverzeichnis (toc) automatisch bauen

            const ctx = this;
            const crawl = function (node, nodeId) {

                const children = Array.from(node.childNodes);
                const header = children.filter(n => n.nodeName === 'HEADER')[0];

                if (header) {
                    header.id = nodeId;
                    ctx.toc.push({
                        id: nodeId,
                        text: header.innerText,

                        /* Leading digits
                         * If the first character of an identifier is numeric, you’ll need to escape it based on its Unicode code point. 
                         * For example, the code point for the character 1 is U+0031, so you would escape it as \000031 or \31 .
                         * 
                         * # => Es handelt sich um einen Id-Selektor.
                         * \\3 => Escapesequenz für numerische Id.
                         * \\. => Punkte müssen ebenfalls escaped werden, damit querySelector diese nicht als Klassen interpretiert.
                         */
                        selector: '#' + '\\3' + nodeId.replaceAll('.', '\\.') + ' ',
                    });
                }

                const sections = children.filter(n => n.nodeName === 'SECTION');
                for (var i = 0; i < sections.length; i++) {
                    crawl(sections[i], [nodeId, i + 1].filter(Boolean).join('.'));
                }
            };

            crawl(this.$refs.content);
        },
    }
</script>
<style scoped>
    /*#region Textfluss*/
    pre {
        white-space: pre-line;
        margin-bottom: 16px;
        font: inherit;
        hyphens: none;
    }

    p {
        text-align: justify;
        word-break: normal;
        hyphens: auto;
    }
    /*#endregion*/

    /*#region Gliederung und Überschriften*/
    section:not(:last-of-type) {
        margin-bottom: 24px;
    }

    section :last-child {
        margin-bottom: 0px;
    }

    section > header {
        margin-bottom: 8px;
        display: flex;
    }

    section > header[id]::before {
        content: attr(id);
        min-width: 3.2rem;
    }
    /*#endregion*/

    /*#region Listen mit Gesetzestexten*/
    ol {
        counter-reset: paragraph;
        list-style: none;
        padding-left: 2em !important;
    }

    ol > li {
        position: relative;
        text-align: start;
    }

    ol > li:not(:last-of-type) {
        margin-bottom: 8px;
    }

    ol > li::before {
        content: "(" counter(paragraph) ") ";
        counter-increment: paragraph;
        position: absolute;
        left: -2em;
    }
    /*#endregion*/

    /*#region Hover-Effekt deaktivieren*/
    .no-hover-effect tr {
        background-color: transparent !important;
    }

    .no-hover-effect td:hover {
        background-color: transparent !important;
    }
    /*#endregion*/

    /*#region Gridlinien neu zeichnen*/
    .gridlines {
        border: solid thin rgba(0, 0, 0, .12);
        border-radius: 0;
    }

    .gridlines td, .gridlines th {
        border-bottom: none !important;
        padding: 4px 8px !important;
    }

    .gridlines td {
        border-top: solid thin rgba(0, 0, 0, .12) !important;
    }

    .gridlines td:not(:first-child), .gridlines th:not(:first-child) {
        border-left: solid thin rgba(0, 0, 0, .12) !important;
    }
    /*#endregion*/

    /*#region Tabellenheader zentrieren*/
    .center-header th {
        text-align: center !important;
    }
    /*#endregion*/

    /*#region Mindestbreite für Tabellenspalten*/
    th, td {
        min-width: 10em;
    }
    /*#endregion*/
</style>